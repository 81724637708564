<template>
    <div class="pt-10">
      <v-layout wrap justify-center  >
      <v-flex pt-lg-2 xs11 sm10 md10 lg10  data-aos="zoom-in-up"
      data-aos-ease="ease"
      data-aos-duration="1500"
      data-aos-delay="500" >
        <v-flex xs12  pb-10 v-for="(item, i) in items" :key="i" >
          <v-card outlined elevation="5" rounded="lg" class="pa-6" >
            <v-layout wrap justify-center>
              <!-- Image on the left -->
              <v-flex xs12 sm6 md4 lg3 >
                <v-img :src="item.img"  height="200px"></v-img>
              </v-flex>
  
              <!-- Content on the right -->
              <v-flex xs12 sm12 md8 lg9 >
                <v-card-title> <span   :style="{
                  'font-size': $vuetify.breakpoint.name == 'xs' ? '17px' 
                  : $vuetify.breakpoint.name == 'xl' ? '20px' : '20px',

                } " 
                  
                  style="font-family: OutfitBold;  color: black;">{{item.title}}</span></v-card-title>
                <v-card-text>
                 
                  <!-- Wrap the rating and span in a flex container -->
                  <!-- <div style="display: flex; align-items: center;">
                   
                    <v-rating 
                      style="margin-left: -10px;"
                      v-model="rating"
                      background-color="orange lighten-3"
                      color="orange"
                      small
                      half-increments 
                    ></v-rating>
                    
                    <span style="font-family: OutfitBold; font-size: 17px; color: black">{{item.review}}</span>&nbsp;&nbsp;<span style="font-family: JostRegular; font-size: 15px; color: grey">{{item.fullreview}}</span>
                  </div> -->
                  <p style="font-family: JostRegular; color: black; font-size: 14px; text-align:justify">{{item.content}}</p>
                </v-card-text>
  
                <v-layout wrap justify-end>
                    <v-flex xs11 sm4 md3 lg2  >
                      <router-link :to="item.route" style="text-decoration: none">
                      <v-btn class="px-5 pa-4" color="primary" small>
                      
                          <span style="color: white; text-transform: capitalize;">Course Details</span>
                       
                      </v-btn>
                    </router-link>
                    </v-flex>
                  </v-layout>
  
                <!-- Button Section -->
                <!-- <v-layout wrap justify-start>
              
               <v-flex xs2 pb-5>
                    <v-btn class="ml-3 pa-4" color="#E6EFF9" style="border-radius: 8px; background-color: #E6EFF9 !important; color: black !important;" small>
                      <v-icon small color="blue">mdi-clock-outline</v-icon>
                      &nbsp; <span style="color: black; text-transform: capitalize;">{{item.months}}</span>
                    </v-btn>
                  </v-flex>
  
            
                  <v-flex pb-5>
                    <v-btn class="pa-4" color="#E6EFF9" style="border-radius: 8px;" small>
                      <v-icon small color="blue">mdi-book-open-outline</v-icon>
                      &nbsp;<span small style="color: black; text-transform: capitalize;">{{item.chapter}}</span>
                    </v-btn>
                  </v-flex>
  
                 
                  <v-layout wrap justify-end>
                    <v-flex xs1 pb-5>
                      <v-btn class="px-5 pa-4" color="primary" small>
                        <router-link to="/coursesingleview" style="text-decoration: none">
                          <span style="color: white; text-transform: capitalize;">Course Details</span>
                        </router-link>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-layout> -->
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-flex>
      </v-layout>
  
  
    </div>
  </template>
  
  <script>
  export default {
    
    data() {
      return {
        items: [
          {
            img: require("./../../assets/images/health.jpg"),
            title: "Health & Safety Online Training",
            
            content:
            "Health and safety is about preventing people from being harmed by work or becoming ill through work and the law applies to all businesses, however large or small. These health and safety training course materials provide required training for all staff members.",
            
            route: "healthandsafetypage",
          },
          {
            img: require("./../../assets/images/manualhandling.jpg"),
            title: "Manual Handling Online Training",
            
            content:
            "Intended primarily for individuals who perform manual tasks in any workplaces, this course provides crucial insights that not only minimise the risk of injuries but also elevate your proficiency in handling manual tasks.  Don't compromise on your safety and equip yourself with the expertise of manual handling best practices, ensuring a safer and more efficient work environment."
            ,
            
            route: "/manualhandlingpage",
          },
          {
            img: require("./../../assets/images/ppe.jpg"),
            title: "Personal Protective Equipment (PPE) online training",
            
            content:
              
            "Wearing PPE while at work is of great importance. It ensures that should any accidents occur, the damage imposed on the wearer is significantly reduced or prevented altogether. Personal protective equipment is defined as equipment, including those that protect from adverse weather conditions, that is intended to be worn or held by a person at work.",
            
            route: "/ppepage",
          },
          {
            img: require("./../../assets/images/lader.jpg"),
            title: "Ladder safety online Training",
           
            content:
            "The ladder safety training program will help employers ensure they are meeting their legal duties to protect from the hazards of ladder use. The course provides a fundamental awareness of setting up, ascending, descending, working from, and storing ladders"
            ,
            
            route: "/ladderpage",
          },

          {
            img: require("./../../assets/images/height.jpg"),
            title: "Working at Heights online Training",
           
            content:
            "The course  covers working safely at heights in resources and infrastructure industries. It includes: identifying the work requirements, work procedures and instructions for the task; accessing and installing equipment; performing work at heights; and cleaning up the work area."
            ,
            
            route: "/heightpage",
          },
         
          
        ],
      };
    },
  };
  </script>
  